@import '../../../../src/styles/colors.scss';

.lead-header {
    padding: 20px 40px 15px 40px;
    color: $white;
    width: 100%;
    min-height: 107px;
    position: relative;

    h2 {
        margin-bottom: 0;
        height: 24px;
    }

    .navigation-container {
        position: absolute;
        bottom: 0;

        div.nav-item {
            text-decoration: none;
            font-size: 15px;
            font-family: d-din;
            font-weight: 400;
            padding: 10px 0 2px 0;
            margin-right: 45px;
            color: $white;
            border-bottom: 4px solid transparent;
            display: inline-block;
            cursor: pointer;
            -webkit-transition: all 180ms ease-in-out 0s;
            -moz-transition: all 180ms ease-in-out 0s;
            -o-transition: all 180ms ease-in-out 0s;
            transition: all 180ms ease-in-out 0s;
    
            &:hover,
            &:focus,
            &:active,
            &.current {
                border-bottom: 4px solid $betfair-yellow;
            }
        }
    
        > a:first-of-type {
            padding-left: 0;
        }
    
        > a:last-child {
            margin-right: 0;
        }
    }

    .lead-header-ranking-container {
        margin-top: 0;

        div.header-ranking {
            -webkit-transition: all 180ms ease-in-out 0s;
            -moz-transition: all 180ms ease-in-out 0s;
            -o-transition: all 180ms ease-in-out 0s;
            transition: all 180ms ease-in-out 0s;
            width: 33.9997%;
            text-align: center;
            padding: 0;
            opacity: 1;

            &.opacity-03 {
                opacity: 0.3;
            }
    
            > div {
                font-size: 18px;
                float: right;

                > span {
                    display: block;
                    font-family: d-din-bold;
                    font-size: 22px;
                    margin-top: 5px;

                    &.display-rank {
                        color: $success;
                    }
                    
                    &.display-score {
                        color: $betfair-yellow;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .lead-header {
        padding: 7px 0 40px 0;

        h2 {
            font-size: 20px;
            height: 20px;
        }

        .navigation-container {
            div.nav-item {
                font-size: 14px;
            }
        }

        .lead-header-ranking-container {
            margin-top: 10px;
            
            div.header-ranking {
                > div {
                    font-size: 14px;

                    > span {
                        font-family: d-din-bold;
                        font-size: 18px;
                        margin-top: -3px;
                    }
                }

                &:nth-child(1) {
                    > div {
                        float: left;
                    }
                }

                &:nth-child(2) {
                    > div {
                        float: none;
                    }
                }

                &:nth-child(3) {
                    > div {
                        float: right;
                    }
                }
            }
        }
    }
}