@import '../../../../src/styles/colors.scss';


/*.bf-table.leaderboard-data tbody {
    display: block;
    height: 333px;
    //overflow-y: scroll;
}
.bf-table.leaderboard-data thead {
    display: table;
    //width: 98.7%;
    width: 100%;
    table-layout: fixed;
}
.bf-table.leaderboard-data tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}*/

.bf-table.leaderboard-data thead {
    display: none !important;
}

.bf-table {
    table.table {
        margin: 0;
    }

    &.user-data-row {
        table.table {
            background-color: $success
        }

        tbody {
            background-color: $success;

            td {
                border-top: 0;
            }
        }

        thead {
            display: none;
        }
    }

    thead {

        th {
            background-color: $light-grey;
            min-height: 50px;
            max-height: 50px;
            height: 50px;
            font-family: d-din;
            font-size: 14px;
            font-weight: 400;
            color: $black;
            border: 0;

            &:last-of-type {
                padding-right: 1.8%;
            }
        }
    }

    tbody {

        tr {

            td {
                font-size: 16px;

                span.rank-up {
                    position: relative;
                    color: $success;
                    font-weight: 600;

                    &:after {
                        content: " ";
                        position: absolute;
                        left: -11px;
                        width: 0;
                        height: 0;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        border-bottom: 6px solid $success;
                        top: 5px;
                    }
                }

                span.rank-down {
                    position: relative;
                    color: $warning;
                    font-weight: 600;

                    &:after {
                        content: " ";
                        position: absolute;
                        left: -10px;
                        width: 0;
                        height: 0;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        border-top: 6px solid $warning;
                        top: 7px;
                    }
                }

                &.punter-column {
                    // padding-left: 18px;
                }
            }

            &.highlight-user-row {
                background-image: repeating-linear-gradient(45deg, rgba(96, 188, 150, 0.05), rgba(96, 188, 150, 0.05) 25px, rgba(96, 188, 150, 0.10) 25px, rgba(96, 188, 150, 0.10) 50px);

                td {
                    font-weight: 600;
                    color: $black;

                    &.punter-column {
                        &:after {
                            content: "You";
                            padding-left: 36px;
                            background-image: url("../../../assets/images/arrow.svg");
                            background-repeat: no-repeat;
                            background-size: contain;
                            margin-left: 8px;
                            color: $success;
                        }
                    }

                    span.rank-up {
                        color: $success;

                        &:after {
                            border-bottom: 6px solid $success;
                        }
                    }

                    span.rank-down {
                        color: $warning;

                        &:after {
                            border-top: 6px solid $warning;
                        }
                    }
                }
            }

            &.highlight-user-row-fixed {
                background-color: $success;

                td {
                    font-weight: 600;
                    color: $white;
                    background-color: $success;

                    span.rank-up {
                        color: $white;

                        &:after {
                            border-bottom: 6px solid $white;
                        }
                    }

                    span.rank-down {
                        color: $white;

                        &:after {
                            border-top: 6px solid $white;
                        }
                    }
                }
            }
        }
    }

    /* table responsive alignment modifications start */
    thead th {
        &:first-of-type {
            padding-left: 40px;
        }

        &:last-of-type {
            padding-right: 40px;
        }
    }
    tbody td {
        &.form-column {
            position: relative;
            left: 12px;
        }

        &.position-column {
            position: relative;
            left: 17px;
        }

        &.margin-column {
            position: relative;
            right: 14px;
        }

        &.score-column {
            position: relative;
            right: 12px;
        }

        &:first-of-type {
            padding-left: 40px;
        }

        &:last-of-type {
            padding-right: 40px;
        }
    }
    /* table responsive alignment modifications end */
}


.lead-body {
    padding: 0;
    min-height: 429px;
    max-height: 429px;
    height: 429px;
    width: 100%;
    overflow: hidden;
    background-color: $white;

    .no-data-message {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}


.lead-footer {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e1e1e+0,595959+100 */
    background: $gradient-black; /* Old browsers */
    background: -moz-linear-gradient(left, $gradient-black 7%, $gradient-grey 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $gradient-black 7%,$gradient-grey 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $gradient-black 7%,$gradient-grey 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-black', endColorstr='$gradient-grey',GradientType=1 ); /* IE6-9 */
    padding: 10px 40px;
    height: 56px;
    width: 100%;
    color: $white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

@media only screen and (max-width: 991px) {
    .lead-footer {
        border-radius: 0;
    }
}

@media only screen and (max-width: 767px) {

    .bf-table {
        /* table responsive alignment modifications start */
        thead th {
            &:first-of-type {
                padding-left: 15px;
            }

            &:last-of-type {
                padding-right: 15px;
            }
        }
        tbody td {
            &:first-of-type {
                padding-left: 15px;
            }

            &:last-of-type {
                padding-right: 15px;
            }
        }
        /* table responsive alignment modifications end */

        tbody {
            tr {
                td {
                    font-size: 14px;

                    &.punter-column {
                        // padding-left: 16px;
                    }
                }
            }
        }
    }

    .lead-body {
        .no-data-message {
            font-size: 14px;
        }
    }

    .lead-footer {
        height: 50px;
    }
}

@media only screen and (max-width: 440px) {
    .bf-table {
        .punter-column-header,
        .punter-column {
            width: 100px !important;
            max-width: 100px !important;
        }
    }
}
