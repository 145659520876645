[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'betfair-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-register .path1:before {
    content: "\e900";
    color: rgb(0, 0, 0);
    opacity: 0.6;
  }
  .icon-register .path2:before {
    content: "\e901";
    margin-left: -1.0625em;
    color: rgb(30, 30, 30);
  }
  .icon-new-window:before {
    font-family: betfair-icons;
    content: "\e902";
  }
  .icon-logo-betfair:before {
    content: "\e903";
  }
  .icon-logo-arrows:before {
    content: "\e904";
  }
  .icon-close-thin:before {
    content: "\e905";
  }
  .icon-search:before {
    content: "\e906";
  }
  .icon-harness:before {
    content: "\e907";
  }
  .icon-greyhounds:before {
    content: "\e908";
  }
  .icon-horse-racing:before {
    content: "\e909";
  }
  .icon-chevron-down:before {
    font-family: 'betfair-icons';
    content: "\e90a";
  }
  .icon-chevron-up:before {
    font-family: 'betfair-icons';
    content: "\e90b";
  }
  .icon-chevron-right:before {
    font-family: betfair-icons;
    content: "\e90c";
  }
  .icon-chevron-left:before {
    font-family: betfair-icons;
    content: "\e90d";
  }
  .icon-time:before {
    font-family: betfair-icons;
    content: "\e90e";
  }
  .icon-filter:before {
    font-family: betfair-icons;
    content: "\e90f";
  }
  .icon-login:before {
    font-family: betfair-icons;
    content: "\e910";
  }
  .icon-notification:before {
    font-family: betfair-icons;
    content: "\e911";
  }
  .icon-youtube:before {
    font-family: betfair-icons;
    content: "\e912";
  }
  .icon-facebook:before {
    font-family: betfair-icons;
    content: "\e913";
  }
  .icon-twitter:before {
    font-family: betfair-icons;
    content: "\e914";
  }
  .icon-google-plus:before {
    font-family: betfair-icons;
    content: "\e915";
  }
  .icon-in-play:before {
    font-family: betfair-icons;
    content: "\e916";
  }