@import '../../../src/styles/colors.scss';

.no-access-container {
    position: relative;
    padding: 0 0 200px 0; /* padding bottom is for footer since our wrapper is position absolute */
    margin: 0;
    width: 100%;
    height: 100%;
    height: 100vh;
    display: table;
    background-image: url('../../assets/images/horse-bg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .top-header-bar {
        height: 50px;
        line-height: 48px;

        .betfair-logo {
            width: 210px;
            height: 50px;
            background-image: url("../../assets/images/BetfairLogo.svg");
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            position: relative;
            top: 12px;
            left: -10px;
            text-decoration: none;
            outline: 0;
        }

        .login-actions {
            -webkit-transition: all 180ms ease-in-out 0s;
            -moz-transition: all 180ms ease-in-out 0s;
            -o-transition: all 180ms ease-in-out 0s;
            transition: all 180ms ease-in-out 0s;

            .login-link {
                color: $white;
                text-decoration: none;
                border-bottom: 4px solid $betfair-yellow;
                padding: 15px 0 16px 0;
                font-size: 18px;
                font-weight: 300;
                font-family: d-din-bold;
            }

            .logout-link {
                .logout-button {
                    color: $white;
                    text-decoration: none;
                    padding: 15px 0 16px 0;
                    border-bottom: 4px solid $betfair-yellow;
                    font-size: 18px;
                    font-weight: 300;
                    font-family: d-din-bold;
                    display: inline;
                    cursor: pointer;
                }
            }
        }
    }

    .bs-container {
        height: 100%;
        height: 100vh;

        .row {
            height: 100%;
            height: 100vh;

            .col {
                position: relative;
                height: 100%;
                height: 100vh;
                -webkit-transform-style: preserve-3d;
                -moz-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }
        }
    }

    .no-access-content {
        width: 400px;
        max-width: 400px;
        text-align: center;
        margin: 0 auto;
        font-family: d-din;
        color: $white;
        background-color: $dark-grey;
        border-radius: 6px;
        padding: 15px;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

        h2 {
            text-transform: none;
        }

        hr {
            background-color: $betfair-yellow;
            width: 100%;
        }

        p {
            font-size: 18px;
            line-height: 1.3;
            margin-bottom: 30px;
        }

        .btn {
            background-color: $betfair-yellow;
            border-color: $betfair-yellow;
            color: $black;
            padding-top: 6px;

            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active,
            &.active:active,
            &.active:focus,
            &.active:hover,
            &:active,
            &:focus,
            &:hover {
                color: $black;
                background-color: $noaccress-hover;
                border-color: $noaccress-hover;
            }
        }
    }

    .no-access-footer-wrapper {
        border-top: 2px $betfair-yellow solid;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .no-access-container {
        background-image: none;

        .top-header-bar {
            .login-actions {
                .login-link {
                    padding: 15px 0 12px 0;
                    font-size: 14px;
                }

                .logout-link {
                    .logout-button {
                        padding: 15px 0 12px 0;
                        font-size: 14px;
                    }
                }
            }
        }

        .no-access-content {
            max-width: 320px;
            width: auto;
            background-color: $black;
            padding: 0;
    
            h2 {
                font-size: 19px;
                margin-bottom: 25px;
            }
    
            hr {
                display: none;
            }
    
            p {
                font-size: 14px;
                margin-bottom: 30px;
            }
    
            .btn {
                padding-top: 4px;
                max-width: 270px;
                margin: 0 auto;
            }
        }

        .no-access-footer-wrapper {
            border-top: 2px $white solid;
        }
    }
}