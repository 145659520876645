@font-face {
    font-family: clarke-light;
    src: url("./assets/fonts/Clarke-Light.otf");
    src: url('./assets/fonts/Clarke-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Clarke-Regular.woff2') format('woff2'),
    url('./assets/fonts/Clarke-Regular.woff') format('woff'),
    url('./assets/fonts/Clarke-Regular.ttf') format('truetype'),
    url('./assets/fonts/Clarke-Regular.svg#Clarke-Regular') format('svg');
  }
  
  @font-face {
    font-family: clarke-black;
    src: url("./assets/fonts/Clarke-Black.otf");
    src: url('./assets/fonts/Clarke-Black.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Clarke-Black.woff2') format('woff2'),
    url('./assets/fonts/Clarke-Black.woff') format('woff'),
    url('./assets/fonts/Clarke-Black.ttf') format('truetype'),
    url('./assets/fonts/Clarke-Black.svg#Clarke-Regular') format('svg');
  }
  
  @font-face {
    font-family: clarke-regular;
    src: url("./assets/fonts/Clarke-Regular.otf");
    src: url('./assets/fonts/Clarke-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Clarke-Regular.woff2') format('woff2'),
    url('./assets/fonts/Clarke-Regular.woff') format('woff'),
    url('./assets/fonts/Clarke-Regular.ttf') format('truetype'),
    url('./assets/fonts/Clarke-Regular.svg#Clarke-Regular') format('svg');
  }
  
  @font-face {
    font-family: d-din-condensed;
    src: url("./assets/fonts/D-DINCondensed.otf");
    src: url('./assets/fonts/D-DINCondensed.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/D-DINCondensed.woff2') format('woff2'),
    url('./assets/fonts/D-DINCondensed.woff') format('woff'),
    url('./assets/fonts/D-DINCondensed.ttf') format('truetype'),
    url('./assets/fonts/D-DINCondensed.svg#Clarke-Regular') format('svg');
  }
  
  @font-face {
    font-family: d-din-condensed-bold;
    src: url("./assets/fonts/D-DINCondensed-Bold.otf");
    src: url('./assets/fonts/D-DINCondensed-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/D-DINCondensed-Bold.woff2') format('woff2'),
    url('./assets/fonts/D-DINCondensed-Bold.woff') format('woff'),
    url('./assets/fonts/D-DINCondensed-Bold.ttf') format('truetype'),
    url('./assets/fonts/D-DINCondensed-Bold.svg#Clarke-Regular') format('svg');
  }
  
  @font-face {
    font-family: d-din-bold;
    src: url("./assets/fonts/D-DIN-Bold.otf");
    src: url('./assets/fonts/D-DIN-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/D-DIN-Bold.woff2') format('woff2'),
    url('./assets/fonts/D-DIN-Bold.woff') format('woff'),
    url('./assets/fonts/D-DIN-Bold.ttf') format('truetype'),
    url('./assets/fonts/D-DIN-Bold.svg#Clarke-Regular') format('svg');
  }
  
  @font-face {
    font-family: d-din;
    src: url("./assets/fonts/D-DIN.otf");
    src: url('./assets/fonts/D-DIN.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/D-DIN.woff2') format('woff2'),
    url('./assets/fonts/D-DIN.woff') format('woff'),
    url('./assets/fonts/D-DIN.ttf') format('truetype'),
    url('./assets/fonts/D-DIN.svg#Clarke-Regular') format('svg');
  }
  
  @font-face {
    font-family: betfair-icons;
    src: url("./assets/fonts/betfair-icons.otf");
    src: url('./assets/fonts/betfair-icons.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/betfair-icons.woff2') format('woff2'),
    url('./assets/fonts/betfair-icons.woff') format('woff'),
    url('./assets/fonts/betfair-icons.ttf') format('truetype'),
    url('./assets/fonts/betfair-icons.svg#Clarke-Regular') format('svg');
  }