.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle.focus,
.show > .btn-secondary.dropdown-toggle:focus,
.show > .btn-secondary.dropdown-toggle.focus,
.btn.focus,
.btn:focus,
.btn-primary.focus,
.btn-primary:focus,
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: none; }

@font-face {
  font-family: clarke-light;
  src: url("./assets/fonts/Clarke-Light.otf");
  src: url("./assets/fonts/Clarke-Regular.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/Clarke-Regular.woff2") format("woff2"), url("./assets/fonts/Clarke-Regular.woff") format("woff"), url("./assets/fonts/Clarke-Regular.ttf") format("truetype"), url("./assets/fonts/Clarke-Regular.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: clarke-black;
  src: url("./assets/fonts/Clarke-Black.otf");
  src: url("./assets/fonts/Clarke-Black.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/Clarke-Black.woff2") format("woff2"), url("./assets/fonts/Clarke-Black.woff") format("woff"), url("./assets/fonts/Clarke-Black.ttf") format("truetype"), url("./assets/fonts/Clarke-Black.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: clarke-regular;
  src: url("./assets/fonts/Clarke-Regular.otf");
  src: url("./assets/fonts/Clarke-Regular.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/Clarke-Regular.woff2") format("woff2"), url("./assets/fonts/Clarke-Regular.woff") format("woff"), url("./assets/fonts/Clarke-Regular.ttf") format("truetype"), url("./assets/fonts/Clarke-Regular.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: d-din-condensed;
  src: url("./assets/fonts/D-DINCondensed.otf");
  src: url("./assets/fonts/D-DINCondensed.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/D-DINCondensed.woff2") format("woff2"), url("./assets/fonts/D-DINCondensed.woff") format("woff"), url("./assets/fonts/D-DINCondensed.ttf") format("truetype"), url("./assets/fonts/D-DINCondensed.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: d-din-condensed-bold;
  src: url("./assets/fonts/D-DINCondensed-Bold.otf");
  src: url("./assets/fonts/D-DINCondensed-Bold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/D-DINCondensed-Bold.woff2") format("woff2"), url("./assets/fonts/D-DINCondensed-Bold.woff") format("woff"), url("./assets/fonts/D-DINCondensed-Bold.ttf") format("truetype"), url("./assets/fonts/D-DINCondensed-Bold.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: d-din-bold;
  src: url("./assets/fonts/D-DIN-Bold.otf");
  src: url("./assets/fonts/D-DIN-Bold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/D-DIN-Bold.woff2") format("woff2"), url("./assets/fonts/D-DIN-Bold.woff") format("woff"), url("./assets/fonts/D-DIN-Bold.ttf") format("truetype"), url("./assets/fonts/D-DIN-Bold.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: d-din;
  src: url("./assets/fonts/D-DIN.otf");
  src: url("./assets/fonts/D-DIN.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/D-DIN.woff2") format("woff2"), url("./assets/fonts/D-DIN.woff") format("woff"), url("./assets/fonts/D-DIN.ttf") format("truetype"), url("./assets/fonts/D-DIN.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: betfair-icons;
  src: url("./assets/fonts/betfair-icons.otf");
  src: url("./assets/fonts/betfair-icons.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/betfair-icons.woff2") format("woff2"), url("./assets/fonts/betfair-icons.woff") format("woff"), url("./assets/fonts/betfair-icons.ttf") format("truetype"), url("./assets/fonts/betfair-icons.svg#Clarke-Regular") format("svg"); }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'betfair-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-register .path1:before {
  content: "\e900";
  color: black;
  opacity: 0.6; }

.icon-register .path2:before {
  content: "\e901";
  margin-left: -1.0625em;
  color: #1e1e1e; }

.icon-new-window:before {
  font-family: betfair-icons;
  content: "\e902"; }

.icon-logo-betfair:before {
  content: "\e903"; }

.icon-logo-arrows:before {
  content: "\e904"; }

.icon-close-thin:before {
  content: "\e905"; }

.icon-search:before {
  content: "\e906"; }

.icon-harness:before {
  content: "\e907"; }

.icon-greyhounds:before {
  content: "\e908"; }

.icon-horse-racing:before {
  content: "\e909"; }

.icon-chevron-down:before {
  font-family: 'betfair-icons';
  content: "\e90a"; }

.icon-chevron-up:before {
  font-family: 'betfair-icons';
  content: "\e90b"; }

.icon-chevron-right:before {
  font-family: betfair-icons;
  content: "\e90c"; }

.icon-chevron-left:before {
  font-family: betfair-icons;
  content: "\e90d"; }

.icon-time:before {
  font-family: betfair-icons;
  content: "\e90e"; }

.icon-filter:before {
  font-family: betfair-icons;
  content: "\e90f"; }

.icon-login:before {
  font-family: betfair-icons;
  content: "\e910"; }

.icon-notification:before {
  font-family: betfair-icons;
  content: "\e911"; }

.icon-youtube:before {
  font-family: betfair-icons;
  content: "\e912"; }

.icon-facebook:before {
  font-family: betfair-icons;
  content: "\e913"; }

.icon-twitter:before {
  font-family: betfair-icons;
  content: "\e914"; }

.icon-google-plus:before {
  font-family: betfair-icons;
  content: "\e915"; }

.icon-in-play:before {
  font-family: betfair-icons;
  content: "\e916"; }

.btn {
  -webkit-transition: all 180ms ease-in-out 0s;
  -moz-transition: all 180ms ease-in-out 0s;
  -o-transition: all 180ms ease-in-out 0s;
  transition: all 180ms ease-in-out 0s; }

.btn-primary {
  font-family: d-din;
  font-size: 14px;
  color: #ffffff;
  background-color: #60bc96;
  border-color: #60bc96;
  min-width: 120px;
  height: 36px;
  text-align: center;
  font-weight: 600;
  padding: 0;
  border-radius: 6px; }
  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary.active:active, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:hover {
    color: #ffffff;
    background-color: #409c76;
    border-color: #409c76; }
  .btn-primary.disabled, .btn-primary:disabled {
    cursor: not-allowed;
    opacity: 0.7;
    background-color: #999999;
    border-color: #999999; }

.btn-secondary {
  font-family: d-din;
  font-size: 14px;
  color: #ffffff;
  background-color: #999999;
  border-color: #999999;
  min-width: 120px;
  height: 30px;
  padding: 0;
  text-align: center;
  font-weight: 600;
  padding: 0;
  border-radius: 6px; }
  .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary.active:active, .btn-secondary.active:focus, .btn-secondary.active:hover, .btn-secondary:active, .btn-secondary:focus, .btn-secondary:hover {
    color: #ffffff;
    background-color: #545b62;
    border-color: #545b62; }
  .btn-secondary.disabled, .btn-secondary:disabled {
    cursor: not-allowed;
    opacity: 0.7;
    background-color: #999999;
    border-color: #999999; }

@media only screen and (max-width: 767px) {
  .btn-secondary,
  .btn-primary {
    min-width: 100px;
    height: 30px; } }

html {
  height: 100%; }

body {
  margin: 0 auto;
  height: 100%;
  padding: 0;
  font-family: d-din;
  font-size: 14px;
  background-color: #1e1e1e; }

.hidden {
  display: none; }

.sticky {
  z-index: 100; }

.content-area {
  background-color: #ededed; }

h2 {
  font-family: d-din-bold;
  font-size: 24px;
  text-transform: uppercase; }

.inner-scroll {
  overflow-y: auto; }

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 20px; } }

.pulse-warning {
  box-shadow: 0 0 0 rgba(212, 89, 89, 0.5);
  -moz-box-shadow: 0 0 0 rgba(212, 89, 89, 0.5);
  -webkit-box-shadow: 0 0 0 rgba(212, 89, 89, 0.5);
  animation: pulse-warning 2s infinite; }

@-webkit-keyframes pulse-warning {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(212, 89, 89, 0.5);
    -moz-box-shadow: 0 0 0 0 rgba(212, 89, 89, 0.5);
    box-shadow: 0 0 0 0 rgba(212, 89, 89, 0.5); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(212, 89, 89, 0);
    -moz-box-shadow: 0 0 0 0 rgba(212, 89, 89, 0);
    box-shadow: 0 0 0 0 rgba(212, 89, 89, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(212, 89, 89, 0);
    -moz-box-shadow: 0 0 0 0 rgba(212, 89, 89, 0);
    box-shadow: 0 0 0 0 rgba(212, 89, 89, 0); } }

@keyframes pulse-warning {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(212, 89, 89, 0.5);
    -moz-box-shadow: 0 0 0 0 rgba(212, 89, 89, 0.5);
    box-shadow: 0 0 0 0 rgba(212, 89, 89, 0.5); }
  70% {
    -webkit-box-shadow: 0 0 0 0 rgba(212, 89, 89, 0);
    -moz-box-shadow: 0 0 0 10px rgba(212, 89, 89, 0);
    box-shadow: 0 0 0 10px rgba(212, 89, 89, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(212, 89, 89, 0);
    -moz-box-shadow: 0 0 0 0 rgba(212, 89, 89, 0);
    box-shadow: 0 0 0 0 rgba(212, 89, 89, 0); } }

.pulse-success {
  box-shadow: 0 0 0 rgba(96, 188, 150, 0.5);
  -moz-box-shadow: 0 0 0 rgba(96, 188, 150, 0.5);
  -webkit-box-shadow: 0 0 0 rgba(96, 188, 150, 0.5);
  animation: pulse-success 2s infinite; }

@-webkit-keyframes pulse-success {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(96, 188, 150, 0.5);
    -moz-box-shadow: 0 0 0 0 rgba(96, 188, 150, 0.5);
    box-shadow: 0 0 0 0 rgba(96, 188, 150, 0.5); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(96, 188, 150, 0);
    -moz-box-shadow: 0 0 0 0 rgba(96, 188, 150, 0);
    box-shadow: 0 0 0 0 rgba(96, 188, 150, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(96, 188, 150, 0);
    -moz-box-shadow: 0 0 0 0 rgba(96, 188, 150, 0);
    box-shadow: 0 0 0 0 rgba(96, 188, 150, 0); } }

@keyframes pulse-success {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(96, 188, 150, 0.5);
    -moz-box-shadow: 0 0 0 0 rgba(96, 188, 150, 0.5);
    box-shadow: 0 0 0 0 rgba(96, 188, 150, 0.5); }
  70% {
    -webkit-box-shadow: 0 0 0 0 rgba(96, 188, 150, 0);
    -moz-box-shadow: 0 0 0 10px rgba(96, 188, 150, 0);
    box-shadow: 0 0 0 10px rgba(96, 188, 150, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(96, 188, 150, 0);
    -moz-box-shadow: 0 0 0 0 rgba(96, 188, 150, 0);
    box-shadow: 0 0 0 0 rgba(96, 188, 150, 0); } }

/* page loader.. Commented out, this is just the scss version but am using in-line style within index.html
 * to ensure the style has loaded before the site has pulled in other content (JS/CSS)
.bf-page-loader-wrapper {
  position: initial !important;
}

.bf-page-loader-overlay {
  background: rgba(0,0,0,0.8) !important;
}

.bf-page-loader-content {
  > div {
    > div {
      background-color: $betfair-yellow;
    }
  }
}*/
