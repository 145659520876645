@import '../../../src/styles/colors.scss';


.header-container {
    color: $white;
    display: block;
    width: 100%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e1e1e+0,595959+100 */
    background: $gradient-black; /* Old browsers */
    background: -moz-linear-gradient(left, $gradient-black 7%, $gradient-grey 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $gradient-black 7%,$gradient-grey 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $gradient-black 7%,$gradient-grey 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-black', endColorstr='$gradient-grey',GradientType=1 ); /* IE6-9 */

    .top-header-bar {
        height: 65px;
        line-height: 65px;

        .betfair-logo {
            width: 210px;
            height: 50px;
            background-image: url("../../assets/images/BetfairLogo.svg");
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            position: relative;
            top: 12px;
            left: -10px;
            text-decoration: none;
            outline: 0;
        }

        .login-actions {
            -webkit-transition: all 180ms ease-in-out 0s;
            -moz-transition: all 180ms ease-in-out 0s;
            -o-transition: all 180ms ease-in-out 0s;
            transition: all 180ms ease-in-out 0s;

            .login-link {
                color: $white;
                text-decoration: none;
                border-bottom: 4px solid $betfair-yellow;
                padding: 15px 0 16px 0;
                font-size: 18px;
                font-weight: 300;
                font-family: d-din-bold;
            }

            .logout-link {
                .logout-button {
                    color: $white;
                    text-decoration: none;
                    padding: 15px 0 16px 0;
                    border-bottom: 4px solid $betfair-yellow;
                    font-size: 18px;
                    font-weight: 300;
                    font-family: d-din-bold;
                    display: inline;
                    cursor: pointer;
                }
            }
        }
    }

    .profile-header-bar {
        padding: 12px 0;
    }

    .navigation-header-bar {
        padding: 5px 0 0 0;
    }

    .hr-line {
        width: 100%;
        background-color: $hr-line;
        height: 1px;
    }
}

@media only screen and (max-width: 767px) {
    .header-container {

        .top-header-bar {
            height: 55px;
            line-height: 55px;

            .betfair-logo {
                width: 200px;
                top: 7px;
            }

            .login-actions {
                .login-link {
                    padding: 15px 0 12px 0;
                    font-size: 14px;
                }
    
                .logout-link {
                    .logout-button {
                        padding: 15px 0 12px 0;
                        font-size: 14px;
                    }
                }
            }
        }

        .profile-header-bar {
            padding: 0 0 5px 0;
        }
    }
}