@import '../../../src/styles/colors.scss';

.content-area {
    .leaderboard-container {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e1e1e+0,595959+100 */
        background: $gradient-black; /* Old browsers */
        background: -moz-linear-gradient(left, $gradient-black 7%, $gradient-grey 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, $gradient-black 7%,$gradient-grey 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, $gradient-black 7%,$gradient-grey 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-black', endColorstr='$gradient-grey',GradientType=1 ); /* IE6-9 */
        margin-top: 25px;
        margin-bottom: 30px;
        border-radius: 6px;
        -moz-box-shadow: 0 0 35px 0px #bbbbbb;
        -webkit-box-shadow: 0 0 35px 0px #bbbbbb;
        box-shadow: 0 0 35px 0px #bbbbbb;
    }
}

@media only screen and (min-width: 992px) {
    .content-area {
        .leaderboard-container {
            .content-responsive {
                > .container {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    max-width: 100%;
        
                    .row {
                        margin: 0;
        
                        .col {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .content-area {
        > .container {
            margin: 0;
            padding: 0;
            width: 100%;
            max-width: 100%;

            .row {
                margin: 0;

                .col {
                    padding: 0;
                }
            }
        }

        .leaderboard-container {
            border-radius: 0;
            margin: 0;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            border-top: 2px solid $hr-line;

            .content-responsive {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
