@import '../../../../src/styles/colors.scss';

.navigation-container {
    position: relative;
    margin-top: 10px;

    a.nav-item {
        text-decoration: none;
        font-size: 18px;
        font-family: d-din;
        font-weight: 400;
        padding: 10px 0 2px 0;
        margin-right: 60px;
        color: $white;
        border-bottom: 4px solid transparent;
        display: inline-block;
        -webkit-transition: all 180ms ease-in-out 0s;
        -moz-transition: all 180ms ease-in-out 0s;
        -o-transition: all 180ms ease-in-out 0s;
        transition: all 180ms ease-in-out 0s;

        &:hover,
        &:focus,
        &:active,
        &.current {
            border-bottom: 4px solid $betfair-yellow;
        }
    }

    > a:first-of-type {
        padding-left: 0;
    }

    > a:last-child {
        margin-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .navigation-container {
        a.nav-item {
            font-size: 14px;
            margin-right: 40px;
        }

        > a:last-child {
            margin-right: 0;
        }
    }
}