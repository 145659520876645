@import '../../../src/styles/colors.scss';

.content-area {
    .acomps-container {
        margin-top: 25px;
        margin-bottom: 30px;
        border-radius: 6px;
        -moz-box-shadow: 0 0 35px 0px #bbbbbb;
        -webkit-box-shadow: 0 0 35px 0px #bbbbbb;
        box-shadow: 0 0 35px 0px #bbbbbb;

        .acomps-content {
            border-radius: 6px;
            padding: 20px 40px 15px 40px;
            min-height: 370px;
            width: 100%;
            background-color: $white;

            .acomps-list {
                padding-top: 15px;
                padding-bottom: 15px;

                .comp-list-tem {
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    line-height: 1.2;
                    padding: 15px;
                    font-size: 18px;
                    font-family: d-din-bold;
                    border-radius: 6px;
                    text-align: center;
                    max-width: 230px;
                    width: 230px;
                    min-height: 50px;
                    height: 50px;
                    border: 1px solid $betfair-yellow;
                    background-color: $white;
                    margin-right: 20px;
                    margin-bottom: 20px;
                    vertical-align: top;
                    -webkit-transition: all 180ms ease-in-out 0s;
                    -moz-transition: all 180ms ease-in-out 0s;
                    -o-transition: all 180ms ease-in-out 0s;
                    transition: all 180ms ease-in-out 0s;

                    &:hover,
                    &:focus,
                    &:active {
                        background-color: $light-grey;
                    }

                    > div {
                        position: relative;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .content-area {
        .acomps-container {
            .content-responsive {
                > .container {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    max-width: 100%;
        
                    .row {
                        margin: 0;
        
                        .col {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .content-area {
        > .container {
            margin: 0;
            padding: 0;
            width: 100%;
            max-width: 100%;

            .row {
                margin: 0;

                .col {
                    padding: 0;
                }
            }
        }

        .acomps-container {
            border-radius: 0;
            margin: 0;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            border-top: 2px solid $hr-line;

            .content-responsive {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .content-area {
        .acomps-container {
            .acomps-content {
    
                .acomps-list {
    
                    div.comp-list-tem {
                        font-size: 16px;
                        width: 44%;
                        min-height: 50px;
                        margin-right: 0;
                        width: 100%;
                        max-width: 100%;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}
