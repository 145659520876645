@import '../../../src/styles/colors.scss';

.content-area {
    .prizes-container {
        margin-top: 25px;
        margin-bottom: 30px;
        border-radius: 6px;
        -moz-box-shadow: 0 0 35px 0px #bbbbbb;
        -webkit-box-shadow: 0 0 35px 0px #bbbbbb;
        box-shadow: 0 0 35px 0px #bbbbbb;

        .prizes-content {
            border-radius: 6px;
            padding: 20px 40px 15px 40px;
            min-height: 380px;
            width: 100%;
            background-color: $white;

            .prizes-data {
                margin: 30px 0;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .content-area {
        .prizes-container {
            .content-responsive {
                > .container {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    max-width: 100%;
        
                    .row {
                        margin: 0;
        
                        .col {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .content-area {
        > .container {
            margin: 0;
            padding: 0;
            width: 100%;
            max-width: 100%;

            .row {
                margin: 0;

                .col {
                    padding: 0;
                }
            }
        }

        .prizes-container {
            border-radius: 0;
            margin: 0;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            border-top: 2px solid $hr-line;

            .content-responsive {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
