@import '../../../../src/styles/colors.scss';

.comp-navbar {
    padding: 10px 40px;
    background-color: $grey;
    min-height: 42px;
    font-family: d-din-bold;
    color: $white;
    -webkit-user-select: none; /* Chrome/Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;

    .previous-round {
        margin-right: 15px;
        cursor: pointer;
    }

    .next-round {
        margin-left: 15px;
        cursor: pointer;
    }

    .previous-round,
    .next-round {
        -webkit-transition: all 130ms ease-in-out 0s;
        -moz-transition: all 130ms ease-in-out 0s;
        -o-transition: all 130ms ease-in-out 0s;
        transition: all 130ms ease-in-out 0s;
        width: 14px;
        height: 14px;

        &.hiding {
            width: 0;
            overflow: hidden;
            margin: 0;
        }
    }
}
