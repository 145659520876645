@import '../../../../src/styles/colors.scss';

.user-profile-container {
    position: relative;
    padding-top: 20px;
    padding-left: 90px;
    width: 100%;
    -webkit-transition: all 180ms ease-in-out 0s;
    -moz-transition: all 180ms ease-in-out 0s;
    -o-transition: all 180ms ease-in-out 0s;
    transition: all 180ms ease-in-out 0s;

    .user-circle {
        position: absolute;
        left: 0;
        top: 12px;
        width: 70px;
        height: 70px;
        background-color: $black;
        border-radius: 70px;
        border: 2px solid $betfair-yellow;
        text-align: center;
        line-height: 68px;

        > span {
            font-family: d-din-bold;
            font-size: 24px;
        }
    }

    .user-details {
        color: $white;

        .user-name {
            font-family: d-din-bold;
            font-size: 24px;
            height: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .punter-detail {
            font-family: d-din;
            font-size: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            > span {
                color: $betfair-yellow;
                padding-left: 6px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .user-profile-container {
        padding-left: 66px;

        .user-circle {
            top: 17px;
            width: 55px;
            height: 55px;
            border-radius: 55px;
            line-height: 53px;

            > span {
                font-size: 20px;
            }
        }

        .user-details {
            .user-name {
                font-size: 18px;
                height: 26px;
            }

            .punter-detail {
                font-size: 13px;
            }
        }
    }
}