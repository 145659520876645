[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'betfair-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-register .path1:before {
  content: "\e900";
  color: black;
  opacity: 0.6; }

.icon-register .path2:before {
  content: "\e901";
  margin-left: -1.0625em;
  color: #1e1e1e; }

.icon-new-window:before {
  font-family: betfair-icons;
  content: "\e902"; }

.icon-logo-betfair:before {
  content: "\e903"; }

.icon-logo-arrows:before {
  content: "\e904"; }

.icon-close-thin:before {
  content: "\e905"; }

.icon-search:before {
  content: "\e906"; }

.icon-harness:before {
  content: "\e907"; }

.icon-greyhounds:before {
  content: "\e908"; }

.icon-horse-racing:before {
  content: "\e909"; }

.icon-chevron-down:before {
  font-family: 'betfair-icons';
  content: "\e90a"; }

.icon-chevron-up:before {
  font-family: 'betfair-icons';
  content: "\e90b"; }

.icon-chevron-right:before {
  font-family: betfair-icons;
  content: "\e90c"; }

.icon-chevron-left:before {
  font-family: betfair-icons;
  content: "\e90d"; }

.icon-time:before {
  font-family: betfair-icons;
  content: "\e90e"; }

.icon-filter:before {
  font-family: betfair-icons;
  content: "\e90f"; }

.icon-login:before {
  font-family: betfair-icons;
  content: "\e910"; }

.icon-notification:before {
  font-family: betfair-icons;
  content: "\e911"; }

.icon-youtube:before, .footer-container .footer-content-container .footer-social-media .social-media-images .link-image-youtube:before {
  font-family: betfair-icons;
  content: "\e912"; }

.icon-facebook:before, .footer-container .footer-content-container .footer-social-media .social-media-images .link-image-facebook:before {
  font-family: betfair-icons;
  content: "\e913"; }

.icon-twitter:before, .footer-container .footer-content-container .footer-social-media .social-media-images .link-image-twitter:before {
  font-family: betfair-icons;
  content: "\e914"; }

.icon-google-plus:before {
  font-family: betfair-icons;
  content: "\e915"; }

.icon-in-play:before {
  font-family: betfair-icons;
  content: "\e916"; }

@media only screen and (max-width: 767px) {
  .footer-container {
    padding-top: 19px;
    padding-bottom: 10px; }
    .footer-container .footer-social-media {
      display: none; }
    .footer-container .footer-content-container {
      padding-bottom: 0px; }
      .footer-container .footer-content-container .footer-texts {
        flex-wrap: wrap;
        font-size: 0.8em; }
        .footer-container .footer-content-container .footer-texts .footer-license-nt {
          margin-bottom: 0.5em; }
        .footer-container .footer-content-container .footer-texts .footer-license-sa {
          margin-bottom: 0.5em; }
        .footer-container .footer-content-container .footer-texts .footer-responsible-gambling {
          margin-bottom: 0.5em; }
        .footer-container .footer-content-container .footer-texts .footer-links {
          margin-bottom: 0.5em; } }

@media only screen and (min-width: 768px) {
  .footer-container {
    padding-top: 15px;
    padding-bottom: 8px;
    width: 100%; }
    .footer-container .footer-social-media {
      display: none; }
    .footer-container .footer-content-container {
      height: 100%; }
      .footer-container .footer-content-container a,
      .footer-container .footer-content-container a:active,
      .footer-container .footer-content-container a:visited,
      .footer-container .footer-content-container a:link {
        text-decoration: none;
        color: #ffb80c; }
      .footer-container .footer-content-container a:hover {
        color: #ffffff; }
      .footer-container .footer-content-container .footer-texts {
        justify-content: center; }
        .footer-container .footer-content-container .footer-texts .footer-license-nt {
          margin-bottom: 20px; }
        .footer-container .footer-content-container .footer-texts .footer-license-sa {
          margin-bottom: 20px; }
        .footer-container .footer-content-container .footer-texts .footer-responsible-gambling {
          margin-bottom: 20px; }
        .footer-container .footer-content-container .footer-texts .footer-links {
          margin-bottom: 20px; } }

.footer-container {
  background-color: #1e1e1e;
  font-family: d-din;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  display: flex;
  flex-wrap: wrap;
  z-index: 2; }
  .footer-container .footer-content-container {
    background-color: #1e1e1e;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }
    .footer-container .footer-content-container .footer-texts {
      background-color: #1e1e1e;
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .footer-container .footer-content-container .footer-texts .footer-license-nt {
        display: flex;
        flex-wrap: wrap;
        color: #ffffff; }
      .footer-container .footer-content-container .footer-texts .footer-license-sa {
        display: flex;
        flex-wrap: wrap;
        color: #666966; }
      .footer-container .footer-content-container .footer-texts .footer-responsible-gambling {
        display: flex;
        flex-wrap: wrap;
        color: #666966; }
      .footer-container .footer-content-container .footer-texts a:link,
      .footer-container .footer-content-container .footer-texts a:visited,
      .footer-container .footer-content-container .footer-texts a:active {
        text-decoration: none;
        color: #ffb80c;
        -webkit-transition: all 180ms ease-in-out 0s;
        -moz-transition: all 180ms ease-in-out 0s;
        -o-transition: all 180ms ease-in-out 0s;
        transition: all 180ms ease-in-out 0s; }
      .footer-container .footer-content-container .footer-texts a:hover {
        color: white; }
      .footer-container .footer-content-container .footer-texts .footer-links {
        display: flex;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        color: #ffb80c; }
        .footer-container .footer-content-container .footer-texts .footer-links .link-item:after {
          content: "|";
          padding: 0 0.5rem; }
        .footer-container .footer-content-container .footer-texts .footer-links .link-item:last-child:after {
          content: ""; }
    .footer-container .footer-content-container .footer-social-media {
      align-self: flex-start;
      background-color: #1e1e1e;
      flex-direction: column;
      flex-wrap: wrap; }
      .footer-container .footer-content-container .footer-social-media .follow-us {
        color: #ffffff;
        margin-bottom: 10px; }
      .footer-container .footer-content-container .footer-social-media .social-media-images {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row; }
        .footer-container .footer-content-container .footer-social-media .social-media-images .link-image, .footer-container .footer-content-container .footer-social-media .social-media-images .link-image-twitter, .footer-container .footer-content-container .footer-social-media .social-media-images .link-image-facebook, .footer-container .footer-content-container .footer-social-media .social-media-images .link-image-youtube {
          border-radius: 2px;
          margin-right: 15px;
          background-color: #1e1e1e;
          color: white;
          font-size: 35px;
          display: flex;
          width: 30px;
          height: 30px;
          align-items: center;
          justify-content: center; }
