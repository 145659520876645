@import '../../../src/styles/colors.scss';

.login-container {
    position: relative;
    padding: 0 0 200px 0; /* padding bottom is for footer since our wrapper is position absolute */
    margin: 0;
    width: 100%;
    height: 100%;
    height: 100vh;
    display: table;

    .top-header-bar {
        height: 50px;

        .logo-wrapper {
            width: 350px;
            margin: 0 auto;
        }

        .betfair-logo {
            width: 350px;
            height: 80px;
            background-image: url("../../assets/images/BetfairLogo.svg");
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            position: relative;
            top: 12px;
            text-decoration: none;
            outline: 0;
            text-align: center;
            margin: 0 auto;
        }
    }

    .bs-container {
        height: 100%;
        height: 100vh;

        .row {
            height: 100%;
            height: 100vh;

            .col {
                position: relative;
                height: 100%;
                height: 100vh;
                -webkit-transform-style: preserve-3d;
                -moz-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }
        }
    }

    .login-wrapper {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 400px;
        max-width: 400px;
        text-align: left;
        margin: 0 auto;
        font-family: d-din;
        color: $white;
        background-color: $light-grey;
        padding: 15px;

        .login-header {
            position: relative;
            border-bottom: 1px solid $black;
            margin-bottom: 30px;

            h2 {
                font-family: d-din-bold;
                font-size: 24px;
                text-align: left;
                display: inline-block;
                color: $betfair-yellow;
                margin-bottom: 5px;
            }
    
            .icon-login {
                position: absolute;
                color: $betfair-yellow;
                right: 0;
                top: 0px;
                font-size: 24px;
            }
        }

        .form-group {
            label {
                font-size: 16px;
                font-family: d-din;
                margin-bottom: 0;
                display: block;
                color: $dark-grey;
            }

            input {
                font-family: d-din;
                border-radius: 4px;
                font-size: 14px;
                width: 100%;
                height: 35px;
                line-height: 33px;
                padding-left: 10px;
                padding-right: 10px;
                background-color: $white;
                border: 1px solid #dedede;
                box-shadow: none;
            }
        }

        .login-footer {
            .button-login {
                background-color: $betfair-yellow;
                font-family: d-din;
                font-weight: 600;
                text-align: center;
                padding: 0 15px;
                line-height: 33px;
                height: 35px;
                border: 0;
                min-width: 80px;
                -webkit-transition: all 180ms ease-in-out 0s;
                -moz-transition: all 180ms ease-in-out 0s;
                -o-transition: all 180ms ease-in-out 0s;
                transition: all 180ms ease-in-out 0s;

                &:hover,
                &:focus,
                &:active {
                    background-color: $noaccress-hover;
                }
            }

            .forgot-account {
                color: $black;
                line-height: 35px;
            }
        }
    }

    .login-footer-wrapper {
        border-top: 2px $white solid;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .login-container {
        background-image: none;

        .login-wrapper {
            max-width: 320px;
            width: auto;
        }
    }
}

@media only screen and (max-width: 400px) {
    .login-container {
        .top-header-bar {
            .logo-wrapper,
            .betfair-logo {
                width: 100%;
            }
        }
    }
}