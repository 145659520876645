@import '../../../../src/styles/colors.scss';

.comp-selector-container {
    position: relative;
    padding-top: 30px;

    .dropdown {
        min-width: 140px;
        text-align: right;

        .dropdown-toggle {
            font-size: 18px;
            font-family: d-din;
            font-weight: 400;
            border: 2px solid $betfair-yellow;
            background-color: $black;
            border-radius: 6px;
            min-width: 140px;
            max-width: 140px;
            text-align: left;
            padding-left: 10px;
            padding-right: 20px;
            max-height: 40px;
            height: 40px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:after {
                position: absolute !important;
                top: 18px !important;
                right: 10px !important;
            }

            &:focus,
            &:hover {
                background-color: $black;
                border-color: $betfair-yellow;
            }
        }

        .dropdown-menu {
            margin-top: -2px;
            border: 2px solid $betfair-yellow;
            background-color: $black;
            min-width: 140px;
            max-width: 140px;
            padding: 0;
            
            > button.dropdown-item {
                color: $white;
                padding-left: 10px;
                padding-right: 10px;
                font-size: 18px;
                font-family: d-din;
                background-color: $black;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                
                &:hover {
                    background-color: $dark-grey;
                }
            }

            button:last-child {
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
            }
        }

        &.show {
            .dropdown-toggle {
                border-bottom: 1px solid transparent;
                z-index: 1001;
                position: relative;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            .dropdown-menu {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }
    
}

@media only screen and (max-width: 767px) {
    .comp-selector-container {
        .dropdown {
            min-width: 100px;

            .dropdown-toggle {
                font-size: 14px;
                min-width: 100px;
                max-width: 100px;
                max-height: 30px;
                height: 30px;
                padding-left: 8px;

                &:after {
                    top: 14px !important;
                    right: 8px !important;
                }
            }

            .dropdown-menu {
                min-width: 100px;
                max-width: 100px;

                > button.dropdown-item {
                    padding-left: 8px;
                    font-size: 14px;
                }
            }
        }
    }
}
