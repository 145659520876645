.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle.focus,
.show > .btn-secondary.dropdown-toggle:focus,
.show > .btn-secondary.dropdown-toggle.focus,
.btn.focus,
.btn:focus,
.btn-primary.focus,
.btn-primary:focus,
.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: none;
}