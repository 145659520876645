@import '../../../../src/styles/colors.scss';

.comp-body {
    padding: 20px 40px 15px 40px;
    min-height: 380px;
    max-height: 380px;
    height: 380px;
    width: 100%;
    background-color: $white;

    .no-data-message {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

        .starting {
            color: $success;
        }

        .closed {
            color: $warning;
        }
    }

    .inner-body-content {
        padding-bottom: 15px;
        font-size: 17px;
        font-family: d-din;
        color: $black;

        .disclaimer-text {
            font-size: 14px;
        }

        /* results header start */
        .results-header {
            font-size: 14px;
            position: absolute;
            right: 0;
            top: 3px;

            .your-pick {
                display: inline-block;
                min-width: 130px;
                width: 130px;
                max-width: 130px;
                text-align: left;
                vertical-align: top;
            }

            .result {
                display: inline-block;
                min-width: 130px;
                width: 130px;
                max-width: 130px;
                text-align: left;
                margin-left: 30px;
                vertical-align: top;
            }

            .points {
                display: inline-block;
                min-width: 25px;
                width: 25px;
                text-align: center;
                margin-left: 18px;
                margin-right: 28px;
                vertical-align: top;
            }

            .closing-odds {
                display: inline-block;
                min-width: 85px;
                width: 85px;
                text-align: center;
                margin-left: 18px;
                margin-right: 28px;
                vertical-align: top;
            }

            .difference {
                display: inline-block;
                min-width: 15px;
                width: 25px;
                text-align: center;
                margin-left: 0;
                margin-right: 48px;
                vertical-align: top;
            }
        }
        /* results header end */

        .form-group {
            margin-bottom: 25px;
            position: relative;

            .s-desc {
                margin-bottom: 6px;
            }

            .s-wrapper {
                position: relative;
                padding: 15px;
                border: 1px solid $light-grey;
                border-radius: 6px;

                > label {
                    margin-bottom: 0;
                    font-weight: 600;
                    line-height: 28px;
                    font-size: 16px;
                    max-width: 310px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: top;
                }

                .btn-radio {
                    padding: 0 7px;
                    border-radius: 4px;
                    line-height: 28px;
                    height: 30px;
                    text-align: center;
                    font-size: 14px;
                    max-width: 130px;
                    width: 130px;
                    font-family: d-din;
                    font-weight: 300;
                    margin: 0 0 0 15px;
                    cursor: pointer;
                    border: 1px solid $light-grey;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-transition: all 180ms ease-in-out 0s;
                    -moz-transition: all 180ms ease-in-out 0s;
                    -o-transition: all 180ms ease-in-out 0s;
                    transition: all 180ms ease-in-out 0s;

                    &:hover,
                    &:focus,
                    &:active {
                        background-color: $light-grey;
                    }

                    &.active {
                        background-color: $success !important;
                        color: $white;
                        font-weight: 600;
                    }

                    > input {
                        display: none;
                    }
                }

                .tie-breaker {
                    max-width: 140px;
                    width: 140px;
                    font-family: d-din;
                    font-weight: 300;
                    font-size: 14px;
                    padding: 0 7px;
                    border-radius: 4px;
                    line-height: 28px;
                    height: 30px;
                    border: 1px solid $light-grey;
                    text-align: center;
                }

                &.field-error {
                    border: 1px solid $warning;

                    .btn-radio,
                    .tie-breaker {
                        border: 1px solid $warning;
                    }
                }

                /* result data start */
                .result-data {
                    font-family: d-din-bold;

                    .your-pick {
                        display: inline-block;
                        min-width: 130px;
                        width: 130px;
                        max-width: 130px;
                        text-align: left;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        vertical-align: top;
                    }

                    .result-pick {
                        display: inline-block;
                        min-width: 130px;
                        width: 130px;
                        max-width: 130px;
                        text-align: left;
                        margin-left: 30px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        vertical-align: top;
                    }

                    .closing-odds {
                        display: inline-block;
                        min-width: 105px;
                        width: 105px;
                        max-width: 105px;
                        text-align: left;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        vertical-align: top;
                        margin-left: 25px;
                    }

                    .points {
                        position: relative;
                        display: inline-block;
                        min-width: 15px;
                        width: 25px;
                        text-align: center;
                        margin-left: 30px;
                        vertical-align: top;

                        .mobile-point-text {
                            display: none;
                        }

                        &.correct {
                            &:after {
                                content: " ";
                                    position: absolute;
                                    left: -8px;
                                    width: 0;
                                    height: 0;
                                    border-left: 4px solid transparent;
                                    border-right: 4px solid transparent;
                                    border-bottom: 6px solid $success;
                                    top: 9px;
                            }
                        }
                    }

                    .wrong {
                        color: $warning;
                    }

                    .correct {
                        color: $success;
                    }
                }
                /* result data end */
            }
        }
    }
}

fieldset {
    &:disabled {
        .comp-body {
            .inner-body-content {
                .form-group {
                    .s-wrapper {
                        .btn-radio {
                            cursor: not-allowed;
                            opacity: 0.7;
                            background-color: #ddd !important;
            
                            &.active {
                                background-color: $dark-grey !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.comp-footer {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e1e1e+0,595959+100 */
    background: $gradient-black; /* Old browsers */
    background: -moz-linear-gradient(left, $gradient-black 7%, $gradient-grey 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $gradient-black 7%,$gradient-grey 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $gradient-black 7%,$gradient-grey 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-black', endColorstr='$gradient-grey',GradientType=1 ); /* IE6-9 */
    padding: 10px 40px;
    height: 56px;
    width: 100%;
    color: $white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .footer-controls {
        .message-box {
            position: relative;
            text-align: right;

            .warning-icon {
                width: 15px;
                height: 15px;
                fill: $warning;
            }

            .success-icon {
                width: 15px;
                height: 15px;
                fill: $success;
            }
        }

        .form-error {
            color: $warning;
            font-weight: 600;
            margin-right: 20px;
            line-height: 33px;

            .hidden {
                display: none;
            }
        }

        .form-success {
            color: $success;
            font-weight: 600;
            margin-right: 20px;
            line-height: 33px;
            opacity: 0;
            -webkit-transition: all 250ms ease-in-out 0s;
            -moz-transition: all 250ms ease-in-out 0s;
            -o-transition: all 250ms ease-in-out 0s;
            transition: all 250ms ease-in-out 0s;

            &.showing {
                opacity: 1;
            }
        }

        /* result data start */
        .total-round-score {
            height: 30px;
            line-height: 30px;
            font-family: d-din-bold;
            font-size: 17px;
            width: 100%;
            text-align: right;

            > span {
                color: $betfair-yellow;
                margin-left: 5px;
            }
        }
        /* result data end */
    }
}

@media only screen and (max-width: 991px) {
    .comp-footer {
        border-radius: 0;
    }
}

@media only screen and (max-width: 767px) {
    .comp-body {
        .no-data-message {
            font-size: 14px;
        }

        .inner-body-content {
            font-size: 14px;

            .results-header {
                display: none;
            }

            .form-group {
                .s-wrapper {
                    padding-top: 8px;
                    padding-bottom: 8px;

                    .btn-group {
                        float: none !important;
                        width: 100%;
                    }

                    > label {
                        display: block;
                        font-size: 14px;
                        line-height: 16px;
                        margin-bottom: 8px;
                        max-width: 100%;
                    }
    
                    .btn-radio {
                        margin: 0;
                        max-width: 50%;
                        width: 50%;

                        &:nth-child(1) {
                            margin-right: 15px;
                        }
                    }
    
                    .tie-breaker {
                        width: 100%;
                        max-width: 100%;
                        float: none !important;
                    }

                    /* result data mobile start */
                    .result-data {
                        float: none !important;

                        .your-pick {
                            display: block;
                            width: 100%;
                            max-width: 100%;

                            &:after {
                                content: "Your Pick:";
                                float: left;
                                width: 90px;
                                font-weight: 400;
                                font-family: d-din;
                                font-size: 14px;
                            }
                        }

                        .result-pick {
                            display: block;
                            width: 100%;
                            max-width: 100%;
                            margin-left: 0;

                            &:after {
                                content: "Result:";
                                float: left;
                                width: 90px;
                                font-weight: 400;
                                font-family: d-din;
                                font-size: 14px;
                                color: $black;
                            }
                        }

                        .closing-odds {
                            display: block;
                            width: 100%;
                            max-width: 100%;
                            margin-left: 0;

                            &:after {
                                content: "Closing Price:";
                                float: left;
                                width: 90px;
                                font-weight: 400;
                                font-family: d-din;
                                font-size: 14px;
                                color: $black;
                            }
                        }

                        .points {
                            display: inline-block;
                            background-color: $dark-grey;
                            color: $white;
                            padding: 0px 12px;
                            margin-top: 7px;
                            border-radius: 10px;
                            font-size: 12px;
                            position: absolute;
                            right: 10px;
                            top: 7px;
                            font-family: d-din;
                            font-weight: 600;
                            width: auto;
                            margin: 0;

                            .mobile-point-text {
                                display: inline;
                            }

                            &.correct {
                                background-color: $success;

                                &:after {
                                    display: none;
                                }
                            }

                            &.wrong {
                                background-color: $warning;
                            }
                        }
                    }
                    /* result data mobile end */
                }
            }
        }
    }

    .comp-footer {
        height: 50px;

        .footer-controls {
            .form-error {
                max-width: 160px;
                line-height: 1;

                &.waiting-for-result {
                    max-width: 290px;
                }
            }

            /* result data start */
            .total-round-score {
                text-align: center;
                font-size: 16px;
            }
            /* result data end */
        }
    }
}
