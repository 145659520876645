@import 'colors.scss';

.btn {
    -webkit-transition: all 180ms ease-in-out 0s;
    -moz-transition: all 180ms ease-in-out 0s;
    -o-transition: all 180ms ease-in-out 0s;
    transition: all 180ms ease-in-out 0s;
}

.btn-primary {
    font-family: d-din;
    font-size: 14px;
    color: $white;
    background-color: $success;
    border-color: $success;
    min-width: 120px;
    height: 36px;
    text-align: center;
    font-weight: 600;
    padding: 0;
    border-radius: 6px;

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &.active:active,
    &.active:focus,
    &.active:hover,
    &:active,
    &:focus,
    &:hover {
        color: $white;
        background-color: $primary-hover;
        border-color: $primary-hover;
    }

    &.disabled,
    &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
        background-color: $grey;
        border-color: $grey;
    }
}

.btn-secondary {
    font-family: d-din;
    font-size: 14px;
    color: $white;
    background-color: $grey;
    border-color: $grey;
    min-width: 120px;
    height: 30px;
    padding: 0;
    text-align: center;
    font-weight: 600;
    padding: 0;
    border-radius: 6px;

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &.active:active,
    &.active:focus,
    &.active:hover,
    &:active,
    &:focus,
    &:hover {
        color: $white;
        background-color: $secondary-hover;
        border-color: $secondary-hover;
    }

    &.disabled,
    &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
        background-color: $grey;
        border-color: $grey;
    }
}

@media only screen and (max-width: 767px) {
    .btn-secondary,
    .btn-primary {
        min-width: 100px;
        height: 30px;
    }
}
