@import '../../../../src/styles/colors.scss';

.comp-header {
    padding: 20px 40px 15px 40px;
    color: $white;
    width: 100%;
    min-height: 107px;

    h2 {
        margin-bottom: 0;
        // height: 24px;
    }

    .current-round {
        margin-top: -3px;

        > span {
            color: $betfair-yellow;
            padding-left: 3px;
        }
    }

    .closing-on {
        > span {
            display: inline-block;
            background-color: $dark-grey;
            color: $white;
            padding: 1px 12px;
            margin-top: 7px;
            border-radius: 10px;
            font-size: 12px;
            font-weight: 600;
        }

        .closed {
            background-color: $warning;
        }

        .closing {
            background-color: $warning;
        }

        .soon {
            background-color: $success;
        }
    }

    .comp-header-ranking-container {
        margin-top: 0;

        div.header-ranking {
            width: 33.9997%;
            text-align: center;
            padding: 0;
    
            > div {
                font-size: 18px;
                float: right;

                > span {
                    display: block;
                    font-family: d-din-bold;
                    font-size: 22px;
                    margin-top: 5px;

                    &.display-rank {
                        color: $success;
                    }
                    
                    &.display-score {
                        color: $betfair-yellow;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .comp-header {
        padding: 7px 0;

        h2 {
            font-size: 20px;
            padding-right: 130px;
        }

        .closing-on {
            position: absolute;
            right: 0;
            top: 0;
        }

        .comp-header-ranking-container {
            margin-top: 10px;

            div.header-ranking {
                > div {
                    font-size: 14px;

                    > span {
                        font-family: d-din-bold;
                        font-size: 18px;
                        margin-top: -3px;
                    }
                }

                &:nth-child(1) {
                    > div {
                        float: left;
                    }
                }

                &:nth-child(2) {
                    > div {
                        float: none;
                    }
                }

                &:nth-child(3) {
                    > div {
                        float: right;
                    }
                }
            }
        }
    }
}