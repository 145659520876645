$betfair-yellow: #ffb80c;
$black: #1e1e1e;
$white: #ffffff;
$light-grey: #ededed;
$grey: #999999;
$dark-grey: #595959;
$success: #60bc96;
$success-rgba-10: rgba(96,188,150,1.0);
$success-rgba-05: rgba(96,188,150,0.5);
$success-rgba-00: rgba(96,188,150,0.0);
$warning: #d45959;
$warning-rgba-10: rgba(212,89,89,1.0);
$warning-rgba-05: rgba(212,89,89,0.5);
$warning-rgba-00: rgba(212,89,89,0.0);

// gradient
$gradient-grey: #595959;
$gradient-black: #1e1e1e;

$hr-line: #8e8e8e;

// button focus/hovers
$primary-hover: #409c76;
$secondary-hover: #545b62;

// no access button focus/hover
$noaccress-hover: #ef980c;
